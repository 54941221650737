
/* Estilos para pantalla grande */
.outerDiv {
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.innerDiv {
    padding: 1px 50px 175px 50px;
    margin: 5% 14%;
    background-color: white;
    box-sizing: border-box;
}

/* Estilos para pantalla pequeña (menor a 600px de ancho)*/
@media only screen and (max-width: 600px) {
    .imgDiv {
        display: none;
    }
    .outerDiv {
        height: auto;
        overflow: auto;
    }
    .innerDiv {
        margin: 5vw;
        padding: 5vh;
    }
}

@media only screen and (max-width: 600px) {
    .imgDiv {
        display: none;
    }
}